<template>
  <div class="grid grid-flow-row gap-4">
    <div class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
        Factura
      </h5>

      <form class="max-w-sm mx-auto">
        <div class="mb-5">
          <div class="form-group">
            <label for="descripcion">Descripción</label>
            <input type="text"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="descripcion" v-model="form.descripcion" required />
          </div>

          <!-- Campo de Selección de Cliente -->
          <div class="form-group">
            <label for="cliente" class="text-black">Cliente</label>
            <select
              class="form-select form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="cliente" v-model="form.clienteId">
              <option value="">Seleccionar cliente</option>
              <option v-for="cliente in clientes" :key="cliente.id" :value="cliente.id">
                {{ cliente.nombreApellido }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="tipo-vehiculo" class="text-black">Servicio</label>
            <select
              class="form-select form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="tipo-vehiculo" v-model="form.servicioId">
              <option value="">Seleccionar servicio</option>
              <option v-for="tipoVehiculo in tiposVehiculos" :key="tipoVehiculo.id" :value="tipoVehiculo.id">
                {{ tipoVehiculo.nombre }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="tipoServicio" class="text-black">Tipo Servicio</label>
            <select
              class="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="tipoServicio" v-model="form.tipoServicioId">
              <option value="" disabled>Selecciona un tipo de servicio</option>
              <option v-for="tipoServicio in tiposDeServicio" :value="tipoServicio.id" :key="tipoServicio.id">
                {{ tipoServicio.nombre }}
              </option>
            </select>
          </div>

         <!-- <div class="form-group">
  <label for="tipoServicio" class="text-black">Tipo Servicio</label>
  <input type="text"
    class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
    id="tipoServicioNombre" v-model="form.tipoServicioNombre" readonly />
</div>-->


          <div class="form-group">
            <label for="importe">Importe</label>
            <input type="text"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="importe" min="1" v-model="form.importe" required  />
          </div>
          <!--<div v-if="isNegativeImport">
            <p class="text-red-600">No ingresar valores negativos</p>
          </div>-->

          <div class="form-group">
            <label for="metodo-pago" class="text-black">Método de pago</label>
            <select
              class="form-control form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="metodo-pago" v-model="form.metodoPago" required>
              <option value="">Seleccionar método de pago</option>
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
            </select>
          </div>

          <div class="form-group" v-if="isAdmin">
            <label for="fecha">Fecha</label>
            <input type="date"
              class="form-control mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
              id="fecha" v-model="form.fecha" required />
          </div>
        </div>

        <button type="submit" @click.prevent="submitForm"
          class="text-black bg-green-300 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
          Guardar
        </button>

        <router-link :to="isAdmin ? '/ventas' : '/caja'"
          class="ml-5 font-medium text-green-500 hover:underline">Cancelar</router-link>
      </form>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, watch  } from "vue";
import { useRouter, useRoute } from "vue-router";
import lavadoService from "../../composables/api/lavadoService.js";
import clienteService from "../../composables/api/clienteService.js";
import cajaService from "../../composables/api/cajaService.js";
import _servicioService from "../../composables/api/servicioService.js";
import tipoServicioService from "../../composables/api/tipoServicioService";

// import { useLavados } from '../services';

export default {
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const idCaja = route.query.idCaja;
    let isAdmin = ref(false);
    let cajaAbierta = ref({});
    let tiposLavados = ref([]);
    let tiposVehiculos = ref([]);
    let isNegativeImport = ref(false);
    let clientes = ref([]);
    let tiposDeServicio = ref([]);
    let tipoServicioNombre = ref([]);

    // alert(idCaja);
    let form = ref({
      id: 0,
      cajaId: 0,
      fecha: "",
      tipoVehiculoId: "",
      tipoLavadoId: "",
      descripcion: "",
      importe: 0,
      responsable: "",
      fecha: "",
      metodoPago: "",
      tipoServicioId: "",
      tipoServicioNombre: '',
      // tipoVehiculo: {
      //   id: 0,
      //   descripcion: '',
      // },
      // tipoLavado: {
      //   id: 0,
      //   descripcion: '',
      // },
    });

    console.log(cajaAbierta);
    // const tiposLavados = ref([{ id: 1, nombre: 'Lavado Común' }]);
    // const tiposVehiculos = useTiposVehiculos();
    // const tiposLavados = useTiposLavados();
    // const lavados = useLavados();

    // const tiposVehiculos = ref([
    //   { id: 1, nombre: 'Camioneta' },
    //   { id: 2, nombre: 'Auto' },
    // ]);

    const submitForm = async () => {
      // form.value.cajaId = cajaAbierta.value.id;
      // form.value.responsable = cajaAbierta.value.responsable;



      // Solo asignar cajaId si cajaAbierta existe y tiene un id
      if (cajaAbierta.value && cajaAbierta.value.id) {
        form.value.cajaId = cajaAbierta.value.id;
      } else {
        form.value.cajaId = null; // Asegurarse de que sea null si no hay caja abierta
      }

      if (form.value.importe < 1) {
        isNegativeImport.value = true;
        return;
      }
      if (form.value.fecha != "") {
        const fecha = new Date(form.value.fecha);
        const fechaFormateada = fecha.toISOString();
        form.value.fecha = fechaFormateada;
      } else {
        form.value.fecha = new Date().toISOString();
      }
      // Validar y asignar el campo clienteId
      if (form.value.clienteId === "") {
        form.value.clienteId = null; // Convertir cadena vacía a null
      } else {
        form.value.clienteId = parseInt(form.value.clienteId, 10); // Convertir a número entero
      }
      if (form.value.id == 0) {
        await lavadoService.addLavado(form);
      } else {
        await lavadoService.editLavado(form.value);
      }
      form.value = {
        id: 0,
        cajaId: 0,
        fecha: "",
        clienteId: 0,
        tipoVehiculoId: "",
        tipoLavadoId: "",
        descripcion: "",
        importe: 0,
        responsable: "",
        metodoPago: ""
      };

      if (isAdmin.value) {
        router.push("/ventas");
        return;
      } else {
        router.push("/ListLavadosCaja");
      }
    };

    const obtenerTiposDeServicio = async () => {
      try {
        const response = await tipoServicioService.getAllTipoServicio();
        console.log(response);
        tiposDeServicio.value = response;
      } catch (error) {
        console.error("Error al obtener los tipos de servicio:", error);
      }
    };

    onMounted(async () => {
      obtenerTiposDeServicio();
      cajaAbierta.value = await cajaService.getCajaAbierta();
      tiposLavados.value = await lavadoService.getTipoLavado();
      // tiposVehiculos.value = await lavadoService.getTipoVehiculo();
      tiposVehiculos.value = await _servicioService.getAllServicios();
      clientes.value = await clienteService.getClientes();
      console.log(clientes.value)

      // Establecer la fecha de hoy por defecto en el campo de fechaRegistro
      const today = new Date();
      form.value.fecha = today.toISOString().split("T")[0]; // Obtener fecha en formato yyyy-MM-dd

      const query = router.currentRoute.value.query;
      if (query.isAdmin === "true") {
        isAdmin.value = true;
      }
      if (query.id != null) {
        console.log(query);
        form.value.id = query.id;
        form.value = await lavadoService.getLavadoById(query.id);
      }

      // alert(isAdmin.value);

      // console.log(tipoLavado);
    });

    const obtenerTipoServicioYImporte = async (servicioId) => {
  try {
    const response = await _servicioService.getById(servicioId);
    console.log(JSON.stringify(response, null, 2)); // Muestra la respuesta completa

    //const tipoServicioId = response.tipoServicioId; 
    const importe = response.precio;

    //let tipoServicioNombre = "Tipo de servicio no disponible"; // Valor por defecto

    // Verificar si tipoServicioId existe y hacer otra llamada si es necesario
   /* if (tipoServicioId) {
      const tipoServicioResponse = await tipoServicioService.getById(tipoServicioId);
      tipoServicioNombre = tipoServicioResponse.nombre || "Tipo de servicio no disponible"; 
    }*/

    // Actualiza los valores en el formulario
    //form.value.tipoServicioNombre = tipoServicioNombre; 
    //form.value.tipoServicioId = tipoServicioId;
    form.value.importe = importe;

    console.log(`Tipo Servicio: ${tipoServicioNombre}, Importe: ${importe}`); // Para depuración
  } catch (error) {
    console.error("Error al obtener el tipo de servicio y el importe:", error);
  }
};

// Agrega esta línea en el setup para observar cambios en el valor de form.servicioId
watch(() => form.value.servicioId, (nuevoValor, antiguoValor) => {
  if (nuevoValor !== antiguoValor && nuevoValor !== "") {
    obtenerTipoServicioYImporte(nuevoValor); 
  }
});

    return {
      isNegativeImport,
      form,
      tiposVehiculos,
      tiposLavados,
      submitForm,
      cajaAbierta,
      idCaja,
      isAdmin,
      clientes,
      tiposDeServicio,
      //tipoServicioNombre
    };
  },
};
</script>